import React, { useEffect } from "react";
import styles from "./curve-chart.module.css";

export default function CurveChart({ title, description, dataset }) {
  const max = Math.max(...dataset);

  useEffect(function sideeffectCallback() {
    const data = dataset.map(function(data, index) {
      return `${index * 20},${100 - Math.floor(100 / (max / data))}`;
    });

    document
      .getElementsByClassName("chartLine")[0]
      .setAttribute("points", data.join(" "));

    const line = document.getElementsByClassName("guide")[0];

    const svg = document.getElementsByTagName("svg")[0];
    svg.addEventListener("mousemove", function(event) {
      const rect = event.target.getBoundingClientRect();
      const pos = event.clientX - rect.left;
      if (pos % 20 === 0) {
        line.setAttribute("x1", pos);
        line.setAttribute("x2", pos);
      }
    });
    svg.addEventListener("mouseout", function() {
      line.setAttribute("x1", 560);
      line.setAttribute("x2", 560);
    });
  });

  return (
    <figure>
      <figcaption>{title}</figcaption>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
        role="img"
        height="100"
        width="540"
      >
        <polyline
          fill="none"
          stroke="#0074d9"
          strokeWidth="2"
          className={"chartLine " + styles.chartLine}
        />

        <line
          x1="560"
          y1="0"
          x2="560"
          y2="100"
          className={"guide " + styles.guide}
        />
      </svg>
    </figure>
  );
}
