import React from "react";
import styles from "./form-field.module.css";

export default function FormField({ name, type, label, placeholder }) {
  return (
    <fieldset className={styles.container}>
      <label for={name} className={styles.label}>
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        className={styles.input}
      />
    </fieldset>
  );
}
