import React from "react";
import SEO from "../presentational/seo";
import Heading from "../presentational/heading";

export default function Dashboard() {
  return (
    <>
      <SEO title="Dashboard" />
      <Heading>Dashboard</Heading>
      <section>
        <p>
          Jaguar shark! So tell me - does it really exist? So you two dig up,
          dig up dinosaurs? Must go faster... go, go, go, go, go! Forget the fat
          lady! You're obsessed with the fat lady! Drive us out of here! Hey,
          you know how I'm, like, always trying to save the planet? Here's my
          chance.
        </p>
        <p>
          What do they got in there? King Kong? God creates dinosaurs. God
          destroys dinosaurs. God creates Man. Man destroys God. Man creates
          Dinosaurs. You know what? It is beets. I've crashed into a beet truck.
          Hey, take a look at the earthlings. Goodbye! Yeah, but John, if The
          Pirates of the Caribbean breaks down, the pirates don’t eat the
          tourists.
        </p>
        <p>
          My dad once told me, laugh and the world laughs with you, Cry, and
          I'll give you something to cry about you little bastard! My dad once
          told me, laugh and the world laughs with you, Cry, and I'll give you
          something to cry about you little bastard! Forget the fat lady! You're
          obsessed with the fat lady! Drive us out of here!
        </p>
        <p>
          You really think you can fly that thing? God help us, we're in the
          hands of engineers. Is this my espresso machine? Wh-what is-h-how did
          you get my espresso machine? Forget the fat lady! You're obsessed with
          the fat lady! Drive us out of here! They're using our own satellites
          against us. And the clock is ticking.
        </p>
      </section>
    </>
  );
}
