import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Redirect } from "react-router-dom";
import Login from "./login";
import MainRouter from "./router";

export default function App() {
  const [user, setUser] = useState(false);

  function userHandler() {
    setUser(!user);
    return <Redirect to="/" />;
  }

  return user ? (
    <MainRouter logout={userHandler} />
  ) : (
    <Login login={userHandler} />
  );
}

const wrapper = document.getElementById("appContainer");
wrapper ? ReactDOM.render(<App />, wrapper) : false;
