import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Header from "./header";
import PrimaryNavigation from "./primary-navigation";
import StatusBar from "./status-bar";
import Dashboard from "../scenes/dashboard";
import SiteStats from "../scenes/site-stats";
import styles from "./router.module.css";

export default function MainRouter({ logout }) {
  return (
    <Router>
      <Header />
      <PrimaryNavigation logout={logout} />
      <main className={styles.main}>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/site-stats" component={SiteStats} />
      </main>
      <StatusBar />
    </Router>
  );
}
