import React from "react";
import styles from "./login.module.css";
import FormField from "../presentational/form-field";

export default function({ login }) {
  return (
    <main className={styles.main}>
      <form>
        <h1>Log in</h1>
        <FormField name="username" type="email" label="Username" />
        <FormField name="password" type="password" label="Password" />
        <button onClick={login}>Log in</button>
      </form>
    </main>
  );
}
