import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../presentational/user-avatar";
import styles from "./user-widget.module.css";
import MenuLink from "../presentational/menu-link";

export default function UserWidget({ logout }) {
  return (
    <section className={styles.widget}>
      <Avatar src="https://lorempixel.com/200/200/people/1" />
      <details className={styles.details}>
        <summary className={styles.username}>Username</summary>
        <ul className={styles.list}>
          <MenuLink to="" name="Settings" />
          <Link onClick={logout}>Log out</Link>
        </ul>
      </details>
    </section>
  );
}
