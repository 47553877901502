import React, { useEffect, createRef } from "react";
import styles from "./pie-chart.module.css";

export default function PieChart({ title, percent }) {
  const pie = createRef();
  useEffect(function sideeffectCallback() {
    const R = 25;
    const C = 2 * Math.PI * R;

    pie.current.setAttribute("r", R);
    pie.current.setAttribute(
      "style",
      `stroke-dasharray: ${(0 * C) / 100} ${C}`
    );
    setTimeout(
      () =>
        pie.current.setAttribute(
          "style",
          `stroke-dasharray: ${(percent * C) / 100} ${C}`
        ),
      10
    );
  });

  return (
    <figure className={styles.figure}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
        role="img"
        height="100"
        width="100"
      >
        <circle
          ref={pie}
          className={styles.pie}
          cx="50"
          cy="50"
          strokeWidth="50"
        />
      </svg>
      <figcaption>
        {title}, {percent}%
      </figcaption>
    </figure>
  );
}
