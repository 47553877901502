import React, { useEffect } from "react";
import styles from "./header.module.css";
import SearchForm from "../presentational/search-form";

export default function Header() {
  return (
    <header className={styles.header}>
      <SearchForm />
    </header>
  );
}
