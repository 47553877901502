import React from "react";
import MenuLink from "../presentational/menu-link";
import styles from "./primary-navigation.module.css";
import UserWidget from "./user-widget";

export default function PrimaryNavigation({ logout }) {
  return (
    <nav className={styles.primaryNavigation}>
      <UserWidget logout={logout} />
      <ul role="menu" className={styles.menu}>
        <MenuLink to="/" name="Dashboard" />
        <MenuLink to="/site-stats" name="Site Statistics">
          <MenuLink
            to="/site-stats/www.brianemilius.com"
            name="www.brianemilius.com"
          />
        </MenuLink>
      </ul>
    </nav>
  );
}
