import React from "react";
import SEO from "../presentational/seo";
import Heading from "../presentational/heading";
import CurveChart from "../presentational/curve-chart";
import PieChart from "../presentational/pie-chart";
import CountUp from "react-countup";
import moment from "moment";

export default function SiteStats() {
  const data = [
    190,
    80,
    100,
    0,
    20,
    300,
    310,
    420,
    100,
    150,
    870,
    550,
    600,
    420,
    180,
    100,
    90,
    0,
    30,
    80,
    1400,
    1450,
    2000,
    1000,
    1010,
    500,
    400,
    360
  ];

  const sum = arr => arr.reduce((a, b) => a + b, 0);

  return (
    <>
      <SEO title="Site Statistics" />
      <Heading>Site Statistics</Heading>
      <h2>28 day report</h2>
      <p>
        Total visits since{" "}
        {moment(moment().subtract(27, "days")).format("MMM DD")}
        : <CountUp end={sum(data)} separator="," />
      </p>
      <CurveChart title="Daily Visits" description="Bar" dataset={data} />
      <PieChart title="US" percent="85" />
      <PieChart title="GB" percent="7" />
      <PieChart title="NL" percent="5" />
      <PieChart title="ES" percent="3" />
    </>
  );
}
