import React, { useEffect } from "react";
import styles from "./search-form.module.css";

export default function SearchForm() {
  useEffect(function sideeffectCallback() {
    const form = document.getElementById("searchForm");

    function formSubmitted(event) {
      event.preventDefault();
    }

    form.addEventListener("submit", formSubmitted);
  });

  return (
    <form id="searchForm">
      <fieldset>
        <label htmlFor="search" className="sr-only">
          Search
        </label>{" "}
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Enter search phrase"
          className={styles.searchInput}
        />
      </fieldset>
      <button type="submit" className={styles.searchButton}>
        S<span className="sr-only">Search</span>
      </button>
    </form>
  );
}
