import React from "react";
import styles from "./user-avatar.module.css";
import PropTypes from "prop-types";

export default function Avatar({ src }) {
  return <img src={src} className={styles.avatar} />;
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired
};
