import React from "react";
import styles from "./heading.module.css";
import PropTypes from "prop-types";

export default function Heading({ children }) {
  return <h1 className={styles.heading}>{children}</h1>;
}

Heading.propTypes = {
  children: PropTypes.string.isRequired
};
