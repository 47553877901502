import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./menu-link.module.css";

export default function MenuLink({ children, name, to }) {
  return (
    <li>
      <Link to={to} className={styles.link}>
        {name}
      </Link>
      <ul>{children}</ul>
    </li>
  );
}

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.element
};
